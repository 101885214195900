import { RouterState } from 'connected-react-router';
import { State } from 'types';
import { initialState as homepage } from './ducks/homepage/reducer';
import { initialState as history } from './ducks/history/reducer';
import { initialState as offers } from './ducks/offers/reducer';
import { initialState as services } from './ducks/services/reducer';
import { initialState as search } from './ducks/search/reducer';
import { initialState as service } from './ducks/service/reducer';
import { initialState as post } from './ducks/post/reducer';
import { initialState as offer } from './ducks/offer/reducer';
import { initialState as event } from './ducks/event/reducer';
import { initialState as initData } from './ducks/app/reducer';
import { initialState as events } from './ducks/events/reducer';

export const getInitialState = (pathname: string = '/'): State => {
    return {
        homepage,
        events,
        history,
        offers,
        service,
        services,
        search,
        post,
        offer,
        initData,
        event,
        router: {
            location: { pathname, search: '', hash: '', key: '' },
            action: 'POP',
        } as RouterState,
    };
};
