import url from 'url';
import path from 'path';
import React from 'react';
import { renderToString } from 'react-dom/server';
import { Request, Response } from 'express';
import { StaticRouter, matchPath } from 'react-router-dom';
import { StaticRouterContext } from 'react-router';
import { Provider as ReduxProvider } from 'react-redux';
import Helmet, { HelmetData } from 'react-helmet';
import { ChunkExtractor } from '@loadable/server';
import { App } from './components/App/App';
import { configureStore } from './store/rootStore';
import rootSaga from './store/rootSaga';
import { getInitialState } from './store/getInitialState';
import routes from './routes';

export default (req: Request, res: Response) => {
    const location = req.url;
    const context: StaticRouterContext = {};
    const { store } = configureStore(getInitialState(location), location);

    function renderApp() {
        const statsFile = path.resolve('./dist/loadable-stats.json');
        const chunkExtractor = new ChunkExtractor({ statsFile });

        const jsx = chunkExtractor.collectChunks(
            <ReduxProvider store={store}>
                <StaticRouter context={context} location={location}>
                    <App />
                </StaticRouter>
            </ReduxProvider>
        );
        const reactHtml = renderToString(jsx);
        const reduxState = store.getState();
        const helmetData = Helmet.renderStatic();

        if (context.url) {
            res.redirect(context.url);
            return;
        }

        res.status(context.statusCode || 200).send(
            getHtml(reactHtml, reduxState, helmetData, chunkExtractor)
        );
    }

    store
        .runSaga(rootSaga)
        .toPromise()
        .then(() => renderApp())
        .catch(err => {
            throw err;
        });

    const dataRequirements: (Promise<void> | void)[] = [];

    /**
     * Call the fetchData method on the component-page
     * that corresponds to the current url (by router).
     *
     * We use `some` method to simulate working of the routes in react-router-dom
     * inside the Switch — selects the first found route.
     */
    routes.some(route => {
        const { fetchData: fetchMethod } = route;
        const match = matchPath<{ slug: string }>(
            url.parse(location).pathname,
            route
        );

        if (match && fetchMethod) {
            dataRequirements.push(
                fetchMethod({
                    dispatch: store.dispatch,
                    match,
                })
            );
        }

        return Boolean(match);
    });

    // When all async actions will be finished,
    // dispatch action END to close saga
    return Promise.all(dataRequirements)
        .then(() => store.close())
        .catch(err => {
            throw err;
        });
};

function getHtml(
    reactHtml: string,
    reduxState = {},
    helmetData: HelmetData,
    chunkExtractor: ChunkExtractor
) {
    const scriptTags = chunkExtractor.getScriptTags();
    const linkTags = chunkExtractor.getLinkTags();
    const styleTags = chunkExtractor.getStyleTags();

    return `
        <!DOCTYPE html>
        <html lang="en">
        <head>

            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="X-UA-Compatible" content="ie=edge">
            <link rel="apple-touch-icon" sizes="180x180" href="https://normdubai.com/assets/images/favicon/apple-touch-icon.png">
            <link rel="icon" type="image/png" sizes="32x32" href="https://normdubai.com/assets/images/favicon/favicon-32x32.png">
            <link rel="icon" type="image/png" sizes="16x16" href="https://normdubai.com/assets/images/favicon/favicon-16x16.png">
            <meta name="msapplication-TileColor" content="#da532c">
            <meta name="theme-color" content="#ffffff">
            <link
                rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css"
            />
            <script src="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js"></script>
            <link
                rel="stylesheet"
                href="https://normdubai.com/main.css"
            />
            ${helmetData.title.toString()}
            ${helmetData.meta.toString()}
            ${helmetData.link.toString()}
            ${linkTags}
            ${styleTags}
            <!-- Google tag (gtag.js) -->
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-6G0N6V6EFF"></script>
            <script>
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-6G0N6V6EFF');
            </script>
            <!-- Yandex.Metrika counter -->
            <script type="text/javascript" >
                (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                m[i].l=1*new Date();
                for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
                k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

                ym(96745534, "init", {
                    clickmap:true,
                    trackLinks:true,
                    accurateTrackBounce:true
                });
            </script>
            <noscript><div><img src="https://mc.yandex.ru/watch/96745534" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
            <!-- /Yandex.Metrika counter -->
        </head>
        <body>

            <div id="mount">${reactHtml}</div>
            <script>
                window.__INITIAL_STATE__ = ${JSON.stringify(reduxState)}
            </script>
            ${scriptTags}
        </body>
        </html>
    `;
}
